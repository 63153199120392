import { GetStaticProps } from 'next'
import NotFound from '@/components/page-types/not-found'
import { fetchStaticProps } from '@/data/fetch-static-props'
import logger from '@ignition/library/lib/logger'

export const getStaticProps: GetStaticProps = async ({ params, preview, previewData }) => {
  try {
    const { props } = await fetchStaticProps({ params: { ...params, slug: ['404'] }, preview, previewData })
    return {
      props,
    }
  } catch (error) {
    logger.error(error)
    throw new Error('Internal Server Error')
  }
}

export default NotFound
